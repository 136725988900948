import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../hooks/useAuth';
import { Link } from 'gatsby';

const LoginButton = styled.div`
  border: none;
  position: relative;
  text-align: center;
  padding-top: 24.5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  &:before {
    content: '\f2bd';
    font-size: 28px;
    font-family: FontAwesome;
    font-display: swap;
    color: #222;
    position: absolute;
    top: 0;
    left: 6px;
  }
  &:visited,
  &:link {
    text-decoration: none;
    color: #222;
  }

  &:active,
  &:hover {
    padding-top: 22.5px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:visited,
  &:link {
    color: inherit;
  }
`;

const Login = () => {
  const { isAuthenticated, login } = useAuth();

  if (!isAuthenticated) {
    return (
      <Link to="https://inspire.hanovercrm.com/users/sign_in">
        <LoginButton id="mybunac-login-button">
        </LoginButton>
      </Link>
    );
  }

  return (
    <Link to="https://inspire.hanovercrm.com/users/sign_in">
        <LoginButton id="mybunac-login-button">
        </LoginButton>
      </Link>
  );
};

export { LoginButton, Login };
