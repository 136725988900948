import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  position: relative;
  z-index: 20;
  margin: 0;
  padding: 0;
  top: 20px;
  right: 20px;
  display: block;
  height: 40px;
  width: 32px;
  -moz-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    i {
      margin-top: 28px;
    }
  }
  
  &.active {
    i {
      color: #222;
    }
    span {
      background: #fff;
      &:before,
      &:after {
        -webkit-transition-delay: 0s, 0.2s;
        transition-delay: 0s, 0.2s;
      }
      &:before {
        top: 0;
        width: 100%;
        background: #222;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 1;
      }
      &:after {
        bottom: 0;
        width: 100%;
        background: #222;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
  @media screen and (min-width: 768px) {
    top: 0;
    right: 0;

    i {
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 767px) {
    width: 16px;
    height: 20px;
    margin-left: 16px;
  }
`;

const StyledIcon = styled.span`
  position: absolute;
  display: block;
  width: 28px;
  height: 3px;
  top: 8.5px;
  right: 0;
  left: 0;
  background: #222;
  -webkit-transition: all 0s 0.2s;
  transition: all 0s 0.2s;
  -webkit-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
  &:after,
  &:before {
    position: absolute;
    display: block;
    left: 0;
    height: 3px;
    border-radius: 50px;
    background-color: #222;
    content: '';
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    -webkit-transition-duration: 0.2s, 0.2s;
    transition-duration: 0.2s, 0.2s;
    -webkit-transition-delay: 0s, 0.2s;
    transition-delay: 0s, 0.2s;
  }
  &:before {
    width: 100%;
    top: -10px;
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, -webkit-transform;
    transition-property: top, transform;
    transition-property: top, transform, -webkit-transform;
  }
  &:after {
    width: 60%;
    bottom: -10px;
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform;
  }
  @media screen and (max-width: 767px) {
    top: -8px;
    height: 4px;
    &:after,
    &:before {
      height: 4px;
    }
    &:before {
      top: -12px;
    }
    &:after {
      bottom: -12px;
    }
  }

`;

interface MenuToggleProps {
  onClick: VoidFunction;
  className: string;
}

const MenuToggle: FunctionComponent<MenuToggleProps> = ({
  onClick,
  ...props
}) => (
  <StyledButton onClick={onClick} {...props}>
    <StyledIcon />
  </StyledButton>
);

export { MenuToggle };
