import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface ContentProps {
  fullWidth?: boolean;
  children: ReactNode;
}

const Content: FunctionComponent<ContentProps> = ({
  children,
  fullWidth = false,
  ...restProps
}) => {
  return (
    <Container
      style={
        fullWidth ? { marginLeft: '0', margin: '0 auto', width: '100%' } : {}
      }
      className="layout-v2"
      {...restProps}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
`;

export { Content };
