import React, { ReactNode, useEffect, FunctionComponent } from 'react';

import '../../assets/css/main.css';
import './css/Complete.css';
import '../../assets/css/FontAwesome.css';
import './css/Table.css';
import './css/FreshChat.css';

import styled from 'styled-components';
import createPersistedState from 'use-persisted-state';

import { Header } from './Header';
import { Footer } from './Footer';
import { FreshChat } from './3rdParty/freshchat';
import { EmbedProximaNovaFont } from './font/EmbedProximaNovaFont';
import { Content } from './Content';
import { Currencies } from '../config/currency';
import { SEO } from '../seo';

const useCurrency = createPersistedState('currency');

interface LayoutProps {
  title: string;
  desc?: string;
  className?: string;
  noIndex?: boolean;
  children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({
  title,
  desc,
  className,
  noIndex,
  children,
}) => {
  const [activeCurrency, setActiveCurrency] = useCurrency(Currencies.default);
  const description = desc || '';

  useEffect(() => {
    setActiveCurrency(activeCurrency);
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="wrapper">
      <SEO
        title={title}
        description={description}
        noIndex={noIndex}
        meta={[
          {
            'facebook-domain-verification': 'hv0ysbd6sfd1xtjxangxx4ccm1p2of',
            charSet: 'utf-8',
          },
        ]}
      />
      <Header />
      <main className={className}>
        {children}
        <Footer />
      </main>
      <EmbedProximaNovaFont />
      <FreshChat />
    </Container>
  );
};

const Container = styled.div``;

export { Content, Layout };
