import React, { useState } from 'react';
import { Logo } from './Logo';
import { CallNow } from './CallNow';
import { Button } from './BookNow';
import { Login, LoginButton } from './Login';
import { MenuToggle } from './MenuToggle';
import styled from 'styled-components';
import { MainNavigation } from '../../Navigation';
import ReviewsRibbon from '../../reviewsBanners/ReviewsRibbon';
import ReviewsRibbonMobile from '@bunac/components/reviewsBanners/ReviewsRibbonMobile';

const Header = () => {
  const [showNavigation, setShowNavigation] = useState(false);

  const toggleNavigationVisibility = () => {
    setShowNavigation(!showNavigation);
  };

  return (
    <Container>
      <Branding>
        <nav>
          <Logo />
          <ReviewsRibbon />
        </nav>
      </Branding>
      <ReviewsRibbonMobile />
      <InfoNav>
        <InfoNavContainer>
          <Pull className={showNavigation ? 'menu_active' : ''}>
            <div>
              {/* <BookNow /> */}
              <Login />
              <CallNow />
              <MenuToggle
                onClick={toggleNavigationVisibility}
                className={showNavigation ? 'show_menu active' : 'show_menu'}
              />
            </div>
          </Pull>
        </InfoNavContainer>
      </InfoNav>
      {showNavigation && <MainNavigation />}
    </Container>
  );
};

const Container = styled.div`
  header,
  nav {
    display: flex;
  }
`;

const Branding = styled.header`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  nav {
    position: relative;
    align-items: center;
    display: flex;
    background-color: #fff;
    margin: 0 auto;
    padding: 16px 32px;
    z-index: 90;
    width: 100%;
    height: 80px;
    -webkit-box-shadow: 0px 9px 20px -5px rgba(0,0,0,0.15);
    box-shadow: 0px 9px 20px -5px rgba(0,0,0,0.15);
  }
  @media screen and (max-width: 767px) {
    nav {
      padding: 16px;
      height: 80px;
    }
  }
  @media screen and (min-width: 1200px) {
    nav {
      max-width: 1200px;
    }
  }
  @media screen and (min-width: 1440px) {
    nav {
      max-width: 1440px;
    }
  }
`;

const InfoNav = styled.div`
  width: 100%;
  height: 80px;
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  @media screen and (max-width: 767px) {
    height: 60px;
  }
`;

const InfoNavContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  @media screen and (min-width: 960px) {
    max-width: 1200px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }
`;

const Pull = styled.div`
  &.menu_active {
    ${LoginButton} {
      color: #fff;
      &:before {
        color: #fff;
      }
    }
    ${Button} {
      color: #fff;
    }

    @media screen and (min-width: 768px) {
      background-color: transparent;
    }
  }
  > div {
    padding: 10px 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    > * {
      pointer-events: all;
      margin-right: 16px;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
  @media screen and (min-width: 768px) {
    background-color: transparent;
    float: right;
    width: 400px;
    > div {
      padding: 28px 36px;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    > div {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end li {
        a {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
`;

export { Header };
