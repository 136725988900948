import React from 'react';
import Helmet from 'react-helmet';

const EmbedProximaNovaFont = () => {
  return (
    <div>
      <Helmet>
        <link
          rel="preload"
          as="style"
          href="https://use.typekit.net/jks0top.css"
        />
      </Helmet>
    </div>
  );
};

export { EmbedProximaNovaFont };
