import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledContainer = styled(Link)`
  position: relative;
  top: -5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (max-width: 48em) {
    top: 5px;
  }
  `;

const Button = styled.div`
  display: flex;
  margin-left: auto;
  background-color: transparent;
  border: 1px solid #0094a5;
  border-radius: 50px;
  padding: 12px 20px 12px 24px;
  transition: All 0.2s ease-out;
  -moz-transition: All 0.2s ease-out;
  -webkit-transition: All 0.2s ease-out;
  -o-transition: All 0.2s ease-out;
  &:hover {
    background-color: #0094a5;
    border: 1px solid transparent;
    span {
      color: #fff;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 13px 24px;
  }
`;

const Text = styled.span`
  display: none;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #0094a5;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const TextSm = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #0094a5;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const IconSm = styled.div`
  margin-left: 8px;
`;

const CallNow = () => (
  <StyledContainer to="/contact">
    <Button>
      <Text>Contact us now</Text>
      <TextSm>Contact
      <IconSm>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
        </svg>
      </IconSm>
      </TextSm>
    </Button>
  </StyledContainer>


);

export { CallNow };
